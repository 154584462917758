import React from "react";
import LessonLayout from "~/layouts/lesson";
import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";
import Quote from "~/components/TextBox/Quote"
import UnorderedList from "~/components/List/Unordered";
import Button from "~/components/button";
import Highlight from "~/components/highlight/highlight";

const Page = () => {
  return (
      <LessonLayout
        current_step={9}
        lesson={"Lesson 1"}
        color={"hs"}
        total_count={10}
        module_title="Separating the Good from the Bad"
      >
        <ContentWrapper>
          <H2 underline>Step 9</H2>

          <p>Now that you have developed skills to assess website features, think back to the question about CBD that you considered at the start of this lesson:</p>

          <Quote>"Is CBD use to relieve chronic pain supported by science and medicine?"</Quote>

          <p>You will need 3 copies of the rubric—one for each of the CBD websites. If you added statements to the criteria column on the rubric you used before, make sure to add them to these copies of the rubric. Use the criteria on your rubric to score each of the CBD websites.</p>

          <Highlight>Only do the first category, "Lesson 1 Website Quality Initial Screen," now.</Highlight>

          <p><strong>Make sure you save all the rubrics.</strong> You will need them again in later lessons. You may want to save them with your name and the name or number of the CBD website. For example, myname_CBD1.</p>

          <Button
            action="secondary"
            className="my-2 lg:m-2"
            color="indigo"
            href="/files/hs/lesson_1/HS__S_full_rubric-scl_final.aw.docx"
            icon="download"
            title="Rubric lesson 1 section 9"
          >
            Rubric
          </Button>

          <div className="flex flex-col lg:flex-row">
            <Button
              action="secondary"
              className="my-2 lg:m-2"
              color="indigo"
              href="/article/cbd/cbd-now"
              icon="external"
              title="CBD Website 1"
            >
              CBD Website 1
            </Button>
            <Button
              action="secondary"
              className="my-2 lg:m-2"
              color="indigo"
              href="/article/cbd/cbd-truth"
              icon="external"
              title="CBD Website 2"
            >
              CBD Website 2
            </Button>
            <Button
              action="secondary"
              className="my-2 lg:m-2"
              color="indigo"
              href="/article/cbd/cansativa-organics"
              icon="external"
              title="CBD Website 3"
            >
              CBD Website 3
            </Button>
          </div>

          <p>Keep the following ideas in mind:</p>

          <UnorderedList>
            <li>Your scores do not need to match those of your teammates. The important part is that you can give a reason for your score.</li>
            <li>You can change your score at any time if you think the evidence supports a change.</li>
          </UnorderedList>
        </ContentWrapper>
      </LessonLayout>
  )
}

export default Page;
